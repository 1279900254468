import React, { createContext, useContext, useState, useEffect } from 'react';
import useFetchData from "@/stores/FetchData";
import { useAlert } from '@/components/AlertContext';
import { AlertTypes } from '@/components/types';
import { useMsal } from '@azure/msal-react';

interface UserInfoContextType {
  userInfo: any;
  setUserInfo: React.Dispatch<React.SetStateAction<any>>;
  login: () => void;
  logout: () => void;
  roles: string[];
  setRoles: React.Dispatch<React.SetStateAction<string[]>>;
  companies: any[];
  setCompanies: React.Dispatch<React.SetStateAction<any[]>>;
  navigateTo: string;
  setNavigateTo: React.Dispatch<React.SetStateAction<string>>;
  refresh: () => Promise<void>;
}

const UserInfoContext = createContext<UserInfoContextType | undefined>(undefined);

export const UserInfoProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [userInfo, setUserInfo] = useState<any>(null);
  const [roles, setRoles] = useState<string[]>([]);
  const [companies, setCompanies] = useState<string[]>([]);
  const [ navigateTo, setNavigateTo] = useState<string>("");
  const { fetchData } = useFetchData();
  const { showAlert } = useAlert();
  const { instance } = useMsal();

  const refresh = async () => {
    let responseData : any;
      try {
        if (localStorage.getItem("user-auth")) {
          const token: any = localStorage.getItem("user-auth");
          const requestData = { method: "GET", endpoint: "/api/user", token: token }

          responseData = await fetchData(requestData);
        }

      } catch (error) {        
        showAlert( "Network error", AlertTypes.WARNING);
      } finally {

        if (responseData?.errors || responseData?.status === '403'){
            const er: any = responseData?.errors[0];
            showAlert(er.title + ": " + er.detail , AlertTypes.ERROR)
            if ( er.title === "Jwt token error"){
                const isMsal = ('authorityType' in userInfo) 
                logout(); 
                if (isMsal) {
                await instance.logout();     
                }
            }
        }

        if (responseData?.roles){
            setRoles(responseData.roles);
        }

        if (responseData?.companies){
            setCompanies(responseData.companies);
        }

        if (responseData?.token){
            localStorage.setItem("user-auth",responseData?.token);
        }
      }; 
  };

  useEffect(() => {
    refresh();

    const storedUserInfo = localStorage.getItem('userInfo');
    if (storedUserInfo) {
      setUserInfo(JSON.parse(storedUserInfo));
    }
    const storedRoles = localStorage.getItem('roles');
    if (storedRoles) {
      setRoles(JSON.parse(storedRoles));
    }
    const storedCompanies = localStorage.getItem('companies');
    if (storedCompanies) {
      setCompanies(JSON.parse(storedCompanies));
    }
  }, []);

  const login = () => {
    const storedUserInfo = localStorage.getItem('userInfo');
    if (storedUserInfo) {
      setUserInfo(JSON.parse(storedUserInfo));
    }
    const storedRoles = localStorage.getItem('roles');
    if (storedRoles) {
      setRoles(JSON.parse(storedRoles));
    }
    const storedCompanies = localStorage.getItem('companies');
    if (storedCompanies) {
      setCompanies(JSON.parse(storedCompanies));
    }
  };
  
  const logout = () => {
    setUserInfo(null);
    setRoles([]);
    setCompanies([]);
    localStorage.removeItem('user-auth');
    localStorage.removeItem('userInfo');
    localStorage.removeItem('roles');
    localStorage.removeItem('companies');
  };

  useEffect(() => {
    if (userInfo) {
      localStorage.setItem('userInfo', JSON.stringify(userInfo));
    } else {
      localStorage.removeItem('userInfo');
    }
  }, [userInfo]);


  return (
    <UserInfoContext.Provider value={{ userInfo, setUserInfo, login, logout, roles, setRoles, companies, setCompanies, navigateTo, setNavigateTo, refresh}}>
      {children}
    </UserInfoContext.Provider>
  );
};

export const useUserInfo = () => {
  const context = useContext(UserInfoContext);
  if (!context) {
    throw new Error('useUserInfo must be used within a UserInfoProvider');
  }
  return context;
};
