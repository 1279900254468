// AlertContext.tsx
import React, { createContext, useContext, useState, ReactNode } from 'react';
import { AlertTypes } from './types';

interface AlertContextType {
  showAlert: (message: string, type?: AlertTypes, timeOut?: number) => void;
}

const AlertContext = createContext<AlertContextType | undefined>(undefined);

export const useAlert = () => {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error('useAlert must be used within an AlertProvider');
  }
  return context;
};

interface AlertProviderProps {
    children: ReactNode; // Define children as ReactNode type
  }

export const AlertProvider: React.FC<AlertProviderProps> = ({ children }) => {
  const [alert, setAlert] = useState<{ message: string; type: AlertTypes; timeOut: number } | null >(null);

  const showAlert = (message: string, type: AlertTypes = AlertTypes.INFO, timeOut: number = 5000) => {
    setAlert({ message, type, timeOut });
    // Automatically hide the alert after 5 seconds
    setTimeout(() => setAlert(null), timeOut);
  };

  return (
    <AlertContext.Provider value={{ showAlert }}>
      {alert && (
        <div className={`alert alert-${alert.type}`} role="alert" style={{ position: 'fixed', top: '0px', left: '0', right: '0', zIndex: 9999 }}>
          {alert.message}
        </div>
      )}
      {children}
    </AlertContext.Provider>
  );
};

