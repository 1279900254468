import React, { ReactNode } from 'react';
import '@/styles/StartItem.css';

interface StartItemProps {
    children: {
      icon?: ReactNode;
      heading?: ReactNode;
      default?: ReactNode;
    };
  }
const StartItem: React.FC<StartItemProps> = ({ children }) => {
    return (
        <div className="item">
          <i>{children.icon}</i>
          <div className="details">
            <h3>{children.heading}</h3>
            {children.default}
          </div>
        </div>
      );
};
export default StartItem;