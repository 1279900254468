import { useState } from "react";
import {RequestData} from '@/components/types'


const useFetchData = () => {
  const [data] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [fetchError, setFetchError] = useState<string | null>(null);

  const fetchData = async (requestData: RequestData) => {
    setLoading(true);
    try {
      const { method, endpoint, token, body } = requestData;
      // console.log(body);
      const backEndUrl = process.env.REACT_APP_BACK_END_URL!;
      const url = backEndUrl + endpoint;
      const requestOptions: RequestInit = {
        method: method,
        headers: {
          'Content-Type': 'application/json',
        }
      };
      if (token) {
        requestOptions.headers = { ...requestOptions.headers, 'Authorization': `Bearer ${token}` };
      }

      if (body) {
        requestOptions.body = JSON.stringify(body);
      }
      const response = await fetch(url, requestOptions);

      const responseData : any = response.json();
      return responseData;

    } catch (error: any) {
      setFetchError(error);
    } finally {
      setLoading(false);
    }
  };

  return { data, loading, fetchError, fetchData };
};

export default useFetchData;
