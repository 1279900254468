// LanguageStore.tsx
import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { LanguagePack } from '../languages/LanguagePack';

// Define the type for the language store
interface LanguageStoreType {
  language: LanguagePack | null;
  initializeLanguage: () => void;
}

// Create a context for the language store
const LanguageContext = createContext<LanguageStoreType | undefined>(undefined);

// Custom hook to access the language store
export const useLanguageStore = () => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error('useLanguageStore must be used within a LanguageProvider');
  }
  return context;
};

// Define props for the LanguageProvider component
interface LanguageProviderProps {
  children: ReactNode; // Define children as ReactNode type
}

// LanguageProvider component
export const LanguageProvider: React.FC<LanguageProviderProps> = ({ children }) => {
  // State to hold the language pack
  const [language, setLanguage] = useState<LanguagePack | null>(null);
  // Function to initialize the language pack
  const initializeLanguage = async () => {
    try {
        // Get the selected language from localStorage or use the browser's default language
        const langCode = localStorage.getItem('selectedLanguage') || navigator.language.substring(0, 2);
        
        // Load the language pack based on the selected language
        const langModule = await import(`./../languages/${langCode}.js`);
        
        // Set the language pack
        setLanguage(langModule.default);
        localStorage.setItem('selectedLanguage',langCode);
      } catch (error) {
        console.error('Failed to initialize language', error);
      }
  };

  // useEffect hook to initialize the language pack when the component mounts
  useEffect(() => {
    initializeLanguage();
  }, []);

  // Provide the language context to child components
  return (
    <LanguageContext.Provider value={{ language, initializeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};
