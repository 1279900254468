import React, { useState } from 'react';
import { useLanguageStore } from '@/stores/LanguageStore';
import { useMsal } from '@azure/msal-react';
import { AuthenticationProvider, Client, ResponseType } from "@microsoft/microsoft-graph-client";
import { useNavigate } from 'react-router-dom';
import { useUserInfo } from '@/stores/UserInfoStore';
import useFetchData from "@/stores/FetchData";
import { useAlert } from '@/components/AlertContext';
import { AlertTypes } from '@/components/types';

interface LoginButtonProps {
  onClick?: () => void;
}

const LoginButton: React.FC<LoginButtonProps> = ({ onClick }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { language } = useLanguageStore();
  const { instance } = useMsal();
  const navigate = useNavigate();
  const {userInfo, login } = useUserInfo();
  const { fetchData } = useFetchData();
  const { showAlert } = useAlert();


  const getProfilePicture = async (loggedAccount: any) => {
      try {
        if (userInfo) {
          return;
        }
  
        // Acquire an access token silently
        const accessTokenResponse = await instance.acquireTokenSilent({
          scopes: ['User.Read'],
          account: loggedAccount
        });
  
        // Define the authentication provider for the Microsoft Graph Client
        const authProvider: AuthenticationProvider = {
          async getAccessToken() {
            return accessTokenResponse.accessToken;
          }
        };
  
        // Initialize the Microsoft Graph Client with middleware
        const client = Client.initWithMiddleware({
          authProvider
        });
        // Retrieve the user's profile picture
        const pictureResponse = await client.api('/me/photo/$value').responseType(ResponseType.ARRAYBUFFER).get();
        const blob = new Blob([pictureResponse], { type: 'image/jpeg' });
        return URL.createObjectURL(blob);
      } catch (error) {
        console.error('Error retrieving profile picture:', error);
      }
    };
  
  const handleLogin = async () => {
    setLoading(true);
    try {
      const loginResponse = await instance.loginPopup();
      const imgUrl = await getProfilePicture(loginResponse.account);
      localStorage.setItem('userInfo', JSON.stringify({ ...loginResponse.account, picture: imgUrl }));

      localStorage.removeItem('user-auth'); 
      const token = loginResponse.account.idToken;
      const requestData = { method: "POST", endpoint: "/api/auth/MSAL", body: {token} };
      const responseData = await fetchData(requestData);
      
      localStorage.setItem('user-auth',responseData?.token);

      login();  
      navigate('/home');  
    } catch (error) {
      showAlert("Failed fetch:/api/auth/MSAL.", AlertTypes.WARNING);
    } finally {
      setLoading(false);
    }
  };

  return (
    <button className = "btn btn-light border-1 border-success m-2" onClick={handleLogin} disabled={loading} style={{ width: '190px' }}>
      {loading ? language?.msal.process : language?.msal.login}
    </button>
  );
};

export default LoginButton;
