import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useLanguageStore } from '@/stores/LanguageStore';
import { MdLanguage } from 'react-icons/md';

const LanguageSlide = () => {
  const { initializeLanguage } = useLanguageStore();
  const [isChecked, setIsChecked] = useState( localStorage.getItem("selectedLanguage") === "lt" );

  const handleToggle = () => {
    // debugger
    

    if( !isChecked ) {
      localStorage.setItem("selectedLanguage","lt");
    }
    else {
      localStorage.setItem("selectedLanguage","en");
    }
    setIsChecked(!isChecked);
    initializeLanguage();

  };  

  return (
    <Form>
      <div style={{ display: 'flex', alignItems: 'center',  }}>
        <MdLanguage  style={{ marginRight: '5px', fontSize: '20px' }}/>
        <span style={{ verticalAlign: 'top' }}>EN</span>
        <Form.Check 
          type="switch"
          id="custom-switch"
          checked={isChecked}
          onChange={handleToggle}
          style={{ margin: '7px' }}   
          label="LT"     
        />
      </div>
    </Form>
  );
};

export default LanguageSlide;
