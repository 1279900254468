import React, { useEffect, useState } from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap'; // Import Bootstrap components
import { useUserInfo } from '@/stores/UserInfoStore';
import { useLanguageStore } from '@/stores/LanguageStore';
import { BiUser, BiCog, BiAddToQueue  } from 'react-icons/bi';
import { FiUser } from 'react-icons/fi';
import '@/styles/NavBar.css'
import { LogoutButton, LanguageSlide } from '@/components/index';


const NavBar: React.FC = () => {
    const logo = process.env.REACT_APP_FAVICON_ICON!;
    const { userInfo, roles } = useUserInfo();
    const { language } = useLanguageStore();
    // const name = ('name' in userInfo) ? userInfo.name : null; 
    const [picture, setPicture] = useState(userInfo?.picture);
    // const picture = ('picture' in userInfo) ? userInfo.picture : null;    
    const isSysAdmin = roles?.includes("SYS_ADMIN");


    useEffect(() => {
      setPicture(userInfo?.picture ? userInfo.picture : null);
    }, [userInfo]); 

                      
    return (  
      <Navbar expand="sm" bg="light" variant="light">

        <Navbar.Brand href="/">
        <img src={logo} className="m-1" width="20" height="20" alt="logo" />
        </Navbar.Brand>
      
      <Navbar.Toggle aria-controls="basic-navbar-nav" />

      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">

          <Nav.Link href="/home">
            <BiAddToQueue style={{ marginRight: '5px', fontSize: '20px' }}/> 
            {language?.text.home}
          </Nav.Link>

          <>
            {isSysAdmin && (
              <Nav.Link href="/sysadmin">
                <BiCog style={{ marginRight: '5px', fontSize: '20px' }}/> 
                {language?.text.systemAdmin}
              </Nav.Link>
            )}
          </>
            
        </Nav>
        <Nav className="mr-auto right">
          <NavDropdown title=
          {<>
          {picture ? (
              <img src={picture} className='circle' alt="" />
              ) : (
              <BiUser style={{ marginRight: '5px', fontSize: '20px' }}/> 
              )}
              {userInfo?.name}
          </>}
          id="profile-nav-dropdown" className="profile-dropdown">
            <div style={{ marginLeft: '15px'}} >
            <LanguageSlide />            
            </div>
            <NavDropdown.Item href="/profile">
              <FiUser style={{ marginRight: '5px', fontSize: '20px' }}/>
              {language?.profile}
            </NavDropdown.Item>  
            <LogoutButton />            
       
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>

  );
};

export default NavBar;
