import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserInfo } from '@/stores/UserInfoStore';
import NavBar from '@/components/NavBar';
import { useAlert } from '@/components/AlertContext';
import { AlertTypes } from '@/components/types';
import { useLanguageStore } from '@/stores/LanguageStore';

const SysAdmin: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { showAlert } = useAlert();
  // storedRoles = localStorage.getItem('roles');
  const { roles, companies, refresh } = useUserInfo();
  const { language } = useLanguageStore();

  useEffect(() => {
    const fetchData = async () => {
      await refresh();
      setLoading(false);
    };

    fetchData();
  }, []);

  useEffect(() => {
    
    const checkUserRoles = async () => {
      if ( !loading && !roles?.includes("SYS_ADMIN")) {
        const accessDenied = language ? language.error.accessDenied : "Error";
        showAlert( accessDenied , AlertTypes.ERROR, 3000);
        navigate('/home');
      }
    }

    checkUserRoles();
  },[roles, loading, navigate, showAlert, language]);
  

  return (
    <div className="app">
      <NavBar /> 
      <div className="col">
        { roles }
      </div>
      <div className="col">
        {companies.map((company: any) => (
          <div key={company.id}>
              {company?.companyName}
          </div>
        ))}
      </div>  
    </div>    
  );
};

export default SysAdmin;