import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserInfo } from '@/stores/UserInfoStore';
import NavBar from '@/components/NavBar';

const Home: React.FC = () => {
  const navigate = useNavigate();
  const { userInfo, roles, companies, refresh } = useUserInfo();
  const [loading, setLoading] = useState(true);
  const hasCompanies = companies?.length > 0;
  const hasRoles = roles?.length > 0;

  useEffect(() => {
    const fetchData = async () => {
      await refresh();
      setLoading(false);
    };

    fetchData();
  }, []);

  useEffect(() => {
    if ( !loading && !userInfo) {
      navigate('/'); // Redirect to '/' if userInfo is falsy
    }
  },[loading, userInfo, navigate]);


  return (
    <div className="app">
      <NavBar /> 
      <div className="row">
        
        <>{hasCompanies && (
        <div className="col">        
          {companies[0]?.companyName}
          </div>
        )}
        </>
        

        <>
        {hasRoles && (
        <div className="col">
          {roles}
          </div>
        )}
        </>
      </div>
    </div>
    
  );
};

export default Home;