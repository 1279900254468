import React from 'react';
import { LanguageProvider } from '@/stores/LanguageStore';
import { UserInfoProvider } from '@/stores/UserInfoStore';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AlertProvider } from '@/components/AlertContext';

  const clientId = process.env.REACT_APP_MSAL_CLIENT_ID!;
  const redirect = process.env.REACT_APP_REDIRECT!;
  const msalConfig = {
    auth: {
      clientId: clientId,
      authority: "https://login.microsoftonline.com/common/",
      redirectUri: redirect,
      // scopes:['user.read','openid', 'profile', 'email','User.ReadBasic.All']
      postLogoutRedirectUri: redirect,
      navigateToLoginRequestUrl: true,
    },
    cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: false,
    },
  };

  const pca = new PublicClientApplication(msalConfig);

  const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID!;

const MyProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    return (
        <LanguageProvider>
          <AlertProvider>
            <UserInfoProvider>
                <MsalProvider instance={pca}> 
                <GoogleOAuthProvider clientId={googleClientId}>
                    {children}
                </GoogleOAuthProvider>
                </MsalProvider>
            </UserInfoProvider>
          </AlertProvider>
        </LanguageProvider>
    );
};

export default MyProvider;