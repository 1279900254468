export interface RequestData {
  method: string;
  endpoint: string;
  token?: string | null;
  body?: object | null;
}

export interface FetchDataResponse<T> {
  data: T | null;
  loading: boolean;
  error: string | null;
}

export enum AlertTypes {
  INFO = 'info',
  WARNING = 'warning',
  SUCCESS = 'success',
  ERROR = 'danger',
}
  