// import '@/styles/App.css';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import React from 'react';
import packageJson from '../package.json';
import { BrowserRouter , Route, Routes} from 'react-router-dom';
import  MyProvider  from '@/stores/MyProvider';
import { Start, Home, SysAdmin, NotFound } from '@/views/index';

const App: React.FC = () => {
  const frontVersion = packageJson.version;
  localStorage.removeItem("frontVersion");
  localStorage.setItem("frontVersion",frontVersion);
  
  return (

    <BrowserRouter> 
      <MyProvider>       
      
      <div id="routes">            
        <Routes>
          <Route path="/" element={<Start />} />
          <Route path="/home"element={<Home />} />
          <Route path="/sysadmin" element={<SysAdmin />} />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
      </MyProvider> 
    </BrowserRouter>
  );
}

export default App;
