// NotFound.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import { useLanguageStore } from '@/stores/LanguageStore';

const NotFound: React.FC = () => {
  const { language } = useLanguageStore();

  return (
    <div className="App">
      <header className="App-header">
      <h2>{language?.error.pageNotFound}</h2>
      <p>{language?.error.pageNotFoundDescription}</p>
      <Link className="nav-item" to="/">{language?.text.toHome}</Link>
      </header>
    </div>
  );
};

export default NotFound;