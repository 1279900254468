import React from 'react';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';
import { useUserInfo } from '@/stores/UserInfoStore';
import { BiLogOut} from 'react-icons/bi';
import { useLanguageStore } from '@/stores/LanguageStore';
import { NavDropdown } from 'react-bootstrap'; // Import Bootstrap components


const LogoutButton: React.FC = () => {
  const { instance } = useMsal();
  const navigate = useNavigate();
  const {userInfo, logout } = useUserInfo();
  const { language } = useLanguageStore();

  const handleLogout = async () => {
    try {
      // debugger
      const isMsal = ('authorityType' in userInfo) 
      logout(); 
      if (isMsal) {
        await instance.logout();     
      }
      navigate("/");
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  return (
    <NavDropdown.Item href="#" onClick={handleLogout}>
      <BiLogOut style={{ marginRight: '5px', fontSize: '20px' }}/>
      {language?.logout}
    </NavDropdown.Item>
  );
};

export default LogoutButton;
