import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLanguageStore } from '@/stores/LanguageStore';
import { useUserInfo } from '@/stores/UserInfoStore';
import { StartItem, MSALlogin, LogoutButton, LanguageSlide } from '@/components/index';
import { GoogleLogin } from '@react-oauth/google';
import { BiFile, BiCart, BiTimer, BiCalendarWeek, BiMoneyWithdraw, BiChat, BiSupport} from 'react-icons/bi';
import { jwtDecode } from "jwt-decode";
import useFetchData from "@/stores/FetchData";
import { useAlert } from '@/components/AlertContext';
import { AlertTypes } from '@/components/types';


const Start: React.FC = () => {
  const locale: string | null = localStorage.getItem("selectedLanguage");
  const navigate = useNavigate();
  const logo = process.env.REACT_APP_FAVICON_ICON!;
  const { language } = useLanguageStore();
  const { userInfo, login, refresh} = useUserInfo();
  const { fetchData } = useFetchData();
  const [apiVersion, setApiVersion] = useState(null);
  const { showAlert } = useAlert();
  const [loading, setLoading] = useState(true);
 
  const getApiVersion = () => {
      
    const getBEVersion= async() => {
      localStorage.removeItem("apiVersion");
      // Call fetchData function with appropriate request data
      const requestData = { method: "GET", endpoint: "/api/auth/version" };
      const responseData = await fetchData(requestData);
      setApiVersion(null);
      if (responseData) {
        setApiVersion(responseData);
        localStorage.setItem("apiVersion", responseData.version);
      }
    };
    
    try {
      getBEVersion();
    } catch (error) {
      console.log(error); // Log any errors
      showAlert( "BackEnd error." , AlertTypes.WARNING, 2000)
      setApiVersion(null);
    };
  };

  useEffect(() => {
    getApiVersion();

    const fetchData = async () => {
      await refresh();
      setLoading(false);
    };
    fetchData();
  },[]);
     
  useEffect(() => {
    if (!loading && userInfo) {
      navigate('/home'); // Redirect to '/' if userInfo is falsy
    }
  },[userInfo, navigate, loading]);

  // const { user } = useGoogleOAuth();
  const handleGoogleLoginSuccess = async (response: any) => {   
    localStorage.removeItem('user-auth'); 
    if (!response || !response.credential) {
      // Handle case where accessToken is missing or null
      showAlert("Google login failed. No access token received.", AlertTypes.WARNING);
      return;
    }

    try {
      const token = response.credential;
      const requestData = { method: "POST", endpoint: "/api/auth/GOAL", body: {token} };
      const responseData = await fetchData(requestData);    
      localStorage.setItem('user-auth',responseData?.token);
      localStorage.setItem('userInfo',JSON.stringify(jwtDecode(response.credential)));
      login();  
      navigate('/home');
    } catch (error:any) {
      showAlert("Failed fetch:/api/auth/GOAL.", AlertTypes.WARNING);
    }
  };

  return (
       <div id="app">
      <header className="header">
        <img src={logo} className="logo"  width="125" height="125" alt="logo" />
          <p className="h3 green">{language?.greeting}</p>
          {apiVersion && (
          <>
            <MSALlogin />
            <GoogleLogin  onSuccess = {handleGoogleLoginSuccess} text={ 'signin' } locale={locale || ''} />
          </>
          )}
          {userInfo && (
            <div>
            <p>Welcome, {userInfo.name}</p>
            <LogoutButton />
          </div>
          )}
          <LanguageSlide />
      </header>
      <div className="container">
      <StartItem>
        {{
          icon: <BiFile />,
          heading: <span>{language?.documentsHeading}</span>,
          default: <span>{language?.documentsText}</span>
        }}
      </StartItem>
      <StartItem>
        {{
          icon: <BiCart/>,
          heading: <span>{language?.shopHeading}</span>,
          default: <span>{language?.shopText}</span>
        }}
      </StartItem>
      <StartItem>
        {{
          icon: <BiTimer/>,
          heading: <span>{language?.resourceHeading}</span>,
          default: <span>{language?.resourceText}</span>
        }}
      </StartItem>
      <StartItem>
        {{
          icon: <BiCalendarWeek/>,
          heading: <span>{language?.planningHeading }</span>,
          default: <span>{language?.planningText}</span>
        }}
      </StartItem>
      <StartItem>
        {{
          icon: <BiMoneyWithdraw/>,
          heading: <span>{language?.financeHeading }</span>,
          default: <span>{language?.financeText }</span>
        }}
      </StartItem>
      <StartItem>
        {{
          icon: <BiChat/>,
          heading: <span>{language?.communicationHeading}</span>,
          default: <span>{language?.communicationText}</span>
        }}
      </StartItem>
      <StartItem>
        {{
          icon: <BiSupport/>,
          heading: <span>{language?.supportHeading}</span>,
          default: <span>{language?.supportText}</span>
        }}
      </StartItem>

      </div>

    </div>
  );
};

export default Start;